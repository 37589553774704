<template>
  <section class="min-w1600">
    <div class="searchwrap">
      <div class="searchBar">
         <div class="box-ui-select searchbar-content">
           <div class="fx">
             <button type="button" class="btn-innerTable btn-status-change wait" @click="detailModalOpen('c')">{{ $t('common.addQ') }}</button>
           </div>
         </div>
      </div>
      <memo></memo>
    </div>
    <div class="main-contents-wrapper">
      <article class="total-info-container fx-col-sbw">
        <div class="total-info-wrap">
          <div class="total-info-item">
            <img :src="require('@/assets/img/searchicon.png')" />
            <i class="fas fa-search"></i>
            <span>{{ $t('searchArea.schResult') }}</span>
          </div>
          <div class="total-info-item">
            <span class="title">{{ $t(`totalInfo.tatal_list_count`) }}</span> : <span class="value">{{ pageInfo.tatal_list_count }}</span>
          </div>
          <div class="total-info-item">
            <span class="title">{{ $t(`totalInfo.page`) }}</span> : <span class="value">{{ pageInfo.page }}</span>
          </div>
          <div class="total-info-item">
            <span class="title">{{ $t(`totalInfo.tatal_page_count`) }}</span> : <span class="value">{{ pageInfo.tatal_page_count }}</span>
          </div>
        </div>
      </article>
      <div class="table-wrapper">
        <table class="mainTable">
          <table-head :headInfo="headInfo" />
          <tbody>
            <template v-for="(item, idx) in tableData" :key="item.boardIdx">
              <tr>
                <td class="roboto">{{ Number(pageInfo.tatal_list_count) - idx }}</td>
                <td>
                  <span>{{ item.category }}</span
                  ><br />
                  <!-- <span>({{ item.memId }})</span> -->
                </td>
                <td class="contentLimit tw-20">{{ item.title }}</td>
                <td class="contentLimit tw-40">
                  {{ item.content }}
                </td>
                <td class="roboto">{{ item.regDate }}</td>
                <td style="width: 10%">
                  <div class="status-change-btn-wrap">
                    <button class="btn-innerTable btn-status-change wait" type="button" @click="getContentDetail(item.boardIdx)">{{ $t('button.modify') }}</button>
                    <button class="btn-innerTable btn-status-change wait" type="button" @click="deleteContent(item.boardIdx)">{{ $t('button.del') }}</button>
                  </div>
                </td>
              </tr>
            </template>
          </tbody>
        </table>
      </div>
    </div>
    <pagination v-if="tableData.length !== 0" @goPage="setTableData" :pageNum="pageInfo.page" :pageSize="pageInfo.count_per_list" :totalCount="pageInfo.tatal_list_count" />

    <div class="modal-wrapper" v-if="updModalActive">
      <div class="modal-wrap">
        <article>
          <div class="sub-title">
            <h3 v-if="modalType === 'u'">{{ $t('button.modify') }}</h3>
            <h3 v-if="modalType === 'c'">{{ $t('button.add') }}</h3>
          </div>
          <div class="page-content">
            <div class="item-row-container">
              <div class="item-wrap">
                <div class="item-title" style="flex: 0 0 10%">{{ $t('table.head.qType') }}</div>
                <div class="item-content">
                  <select v-model="boardDetail.category">
                    <option v-for="item in categorys" :value="item.code" :key="item.code">{{item.codeName}}</option>
                  </select>
                </div>
              </div>
            </div>
            <div class="item-row-container">
              <div class="item-wrap">
                <div class="item-title" style="flex: 0 0 10%">{{ $t('table.head.qTitle') }}</div>
                <div class="item-content">
                  <input type="text" v-model="boardDetail.title" />
                </div>
              </div>
            </div>
            <div class="item-row-container">
              <div class="item-wrap">
                <div class="item-title" style="flex: 0 0 10%">{{ $t('table.head.qContent') }}</div>
                <div class="item-content"><textarea v-model="boardDetail.content"></textarea></div>
              </div>
            </div>
            <!-- <div class="item-row-container">
              <div class="item-wrap">
                <div class="item-title" style="flex: 0 0 8.5%">우선 순위</div>
                <div class="item-content">
                  <input type="text" value="1" />
                </div>
              </div>
            </div> -->
            <div class="modal-btns">
              <a @click="updateBoard(boardDetail.boardIdx)" v-if="modalType === 'u'">{{ $t('button.save') }}</a>
              <a @click="saveBoard(boardDetail.boardIdx)" v-if="modalType === 'c'">{{ $t('button.save') }}</a>
              <a @click="modalClose()">{{ $t('button.close') }}</a>
            </div>
          </div>
        </article>
      </div>
    </div>
  </section>
</template>

<script>
import lodash from 'lodash'
import TableHead from '@/components/main/table/Head.vue'
import BtnVisibleToggle from '@/components/ui/BtnVisibleToggle'
import SearchFilter from '@/components/common/SearchFilter'
import DateSelector from '@/components/common/DateSelector'
import Pagination from '@/components/common/Pagination'
import typing from '@/components/ui/Typing'
import UiSelect from '@/components/ui/Select'
import UiCheckSet from '@/components/ui/UiCheckSet'
import UiCheck from '@/components/ui/UiCheck'
import { getSiteData } from '@/libs/auth-helper'
import { boardList, boardDetail, boardSave, getCode } from '@/api/member.js'
import { replaceDateT, numberWithCommas, getDateStr } from '@/libs/utils.js'
import Memo from '@/components/common/memo'
export default {
  name: 'BoardFaq',
  components: {
    UiSelect,
    UiCheckSet,
    UiCheck,
    BtnVisibleToggle,
    TableHead,
    SearchFilter,
    Pagination,
    DateSelector,
    typing,
    Memo
  },
  data () {
    return {
      tableName: this.$route.name,
      headInfo: {
        fstColumn: false,
        dataList: {
          index: 3,
          type: 10,
          qTitle2: 0,
          content: 0,
          writedate: 10,
          option: 10
        }
      },
      modalType: '',
      reqData: {
        page: 1,
        count_per_list: 30,
        boardType: 'favor',
        category: '',
        startDate: '',
        endDate: ''
      },
      dateConfigs: {
        enableTime: false,
        dateFormat: 'Y-m-d'
      },
      startDefault: '',
      endDefault: '',
      tableData: [],
      pageInfo: {},
      updModalActive: false,
      boardType: 'favor',
      boardDetail: {},
      categorys: []
    }
  },
  methods: {
    async getCategory () {
      const res = await getCode('bcategory', 'favor')
      console.log(res.filter(item => item.code !== 'all'))
      return await res.filter(item => item.code !== 'all')
    },
    setStartDate (date) {
      // console.log(date);
      let _date = ''
      if (!date) {
        _date = new Date()
      } else {
        _date = date[0]
      }
      this.startDefault = _date
      this.reqData.startDate = getDateStr(_date, 'yyyy-MM-dd')
    },
    setEndDate (date) {
      // console.log(date[0]);
      let _date = ''
      if (!date) {
        _date = new Date()
      } else {
        _date = date[0]
      }
      this.endDefault = _date
      this.reqData.endDate = getDateStr(_date, 'yyyy-MM-dd')
    },
    goSearch (page) {
      this.setTableData(page)
    },
    pageSeach () {
      this.setTableData()
    },
    async deleteContent (boardIdx) {
      const trigger = confirm('해당 게시물을 삭제하시겠습니까?')
      if (!trigger) {
        return
      }
      const req = {
        boardIdx,
        delYn: 'Y'
      }
      const boardType = this.boardType
      const res = await boardSave(boardType, req)
      console.log(res)
      if (res.resultCode === '0') {
        alert('게시물 삭제 완료')
        this.setTableData()
      } else {
        alert('게시물 삭제 실패, 다시 시도해주세요.')
      }
    },
    async updateBoard (boardIdx) {
      const trigger = confirm('해당 게시물을 수정하시겠습니까?')
      if (!trigger) {
        return
      }
      const req = {
        boardIdx,
        title: this.boardDetail.title,
        content: this.boardDetail.content,
        category: this.boardDetail.category
      }
      const res = await boardSave(this.boardType, req)
      console.log(res)
      if (res.resultCode === '0') {
        alert('게시물 수정 완료')
        this.setTableData()
        this.modalClose()
      } else {
        alert('게시물 수정 실패, 다시 시도해주세요.')
      }
    },
    async saveBoard () {
      const siteInfo = this.getSiteInfo()
      const trigger = confirm('해당 게시물을 등록 하시겠습니까?')
      if (!trigger) {
        return
      }
      const req = {
        memId: siteInfo.siteId,
        boardType: 'favor',
        category: this.boardDetail.category,
        title: this.boardDetail.title,
        content: this.boardDetail.content
      }
      const res = await boardSave(this.boardType, req)
      console.log(res)
      if (res.resultCode === '0') {
        alert('게시물 저장 완료')
        this.setTableData()
        this.modalClose()
      } else {
        alert('게시물 등록 실패, 다시 시도해주세요.')
      }
    },
    getSiteInfo () {
      const siteInfoCookie = getSiteData()
      if (siteInfoCookie) {
        const siteInfo = JSON.parse(siteInfoCookie)
        return siteInfo
      }
    },
    async createBoard () {
      const siteInfo = this.getSiteInfo()
      const trigger = confirm('해당 게시물을 등록 하시겠습니까?')
      if (!trigger) {
        return
      }
      const req = {
        memId: siteInfo.siteId,
        title: this.boardDetail.title,
        content: this.boardDetail.content,
        categoryType: this.boardDetail.category
      }
      const res = await boardSave(this.boardType, req)
      console.log(res)
      if (res.resultCode === '0') {
        alert('게시물 등록 완료')
        this.setTableData()
        this.modalClose()
      } else {
        alert('게시물 등록 실패, 다시 시도해주세요.')
      }
    },
    modalClose () {
      this.updModalActive = false
      this.boardDetail = {}
    },
    detailModalOpen (type) {
      this.modalType = type
      if (type === 'c') {
        this.boardDetail = {}
      }
      this.updModalActive = true
    },
    async getContentDetail (boardIdx) {
      const req = {
        boardIdx
      }
      const res = await boardDetail(this.boardType, req)
      this.boardDetail = res.data.board
      console.log(this.boardDetail)
      this.detailModalOpen('u')
    },
    async setTableData (page) {
      if (page) {
        this.reqData.page = page
      } else {
        this.reqData.page = 1
      }
      const req = lodash.cloneDeep(this.reqData)
      const boardType = this.boardType
      const res = await boardList(boardType, req)
      // console.log(res);
      if (res.data.pageInfo) {
        this.pageInfo = res.data.pageInfo
        this.pageInfo.tatal_list_count = numberWithCommas(this.pageInfo.tatal_list_count)
        this.pageInfo.tatal_page_count = numberWithCommas(Number(this.pageInfo.tatal_page_count) + 1)
      }
      console.log(res.data.list)
      this.tableData = res.data.list
    }
  },
  async created () {
    this.categorys = await this.getCategory()
    console.log(this.categorys)
    this.setTableData(1)
  }
}
</script>
<style scoped>
.mainTable{
  table-layout: fixed !important;
}
.sub-title {
   color: #646464;
   font-size: 13px;
   padding-bottom: 11px;
   border-bottom: 2px solid #959595;
   display: flex;
   align-items: center;
   justify-content: space-between;
}
textarea {
  width: 100%;
  min-height: 134px;
  border: 1px solid #aaa;
  resize: none;
  box-sizing: border-box;
  border-radius: 2px;
  background-color: #fafafa;
  padding: 10px;
}
.pt50 {
  padding-top: 50px;
}
.modal-wrapper {
  display: flex;
  font-size: 16px;
}
.contentLimit {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  text-align: left;
}
</style>
